import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import classnames from 'classnames';
import Moment from 'moment';
import _ from 'underscore';

import ogImg from '../../assets/img/og.jpg';


import Buttons from '../../components/home/Buttons';
import Events from '../../components/home/Events';
import Image from '../../components/home/Image';
import Mission from '../../components/home/Mission';
import News from '../../components/home/News';
import Gallery from '../../components/home/Gallery';
import allNews from '../../components/news/allNews';
import Tweet from '../../components/home/Tweet';
import ValueImage from '../../components/home/ValueImage';
import Values from '../../components/home/Values';
import Weather from '../../components/home/Weather';

import Footer from '../../components/shared/Footer';
import NavBar from '../../components/shared/NavBar';

class Home extends Component { 
	constructor(props) {
		super(props);
		this.onTransitionEnd.bind(this);
	}

	state = {
		transitionEnded: false,
	};
	onTransitionEnd = () => {
		this.setState({ transitionEnded: true }); 
	};

	render() {
		const heroClasses = classnames('hero', {
			animate: this.state.transitionEnded
		});
		//if this.props.homePageQuery is null wait, if not null check state of homePageQuery until done loading
		if (!this.props.homePageQuery){
			return <div className="u-hidden"> Waiting</div>;
		}

		else if (this.props.homePageQuery && this.props.homePageQuery.loading) { 
			return <div className="u-hidden">Loading</div>; 
		}

		else if (this.props.homePageQuery && this.props.homePageQuery.error) {
			return <div className="u-hidden">Error </div>;
		}

		//done loading
		this.data = this.props.homePageQuery.globalSites[0];
		if (!this.data) {
			window.location.href = '/404';
			return null;
		} 
		let today = new Date();
		let compare = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0 );
		const tempEvents = _.filter(this.props.homePageQuery.eventItems, function(d){
			return Moment(compare) <= Moment(d.eventDate);
		});

		return(	
			<div key="home" className={heroClasses}>   
				<Helmet>
					<meta property="og:image" content={ogImg}/>
					<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"/>
				</Helmet>
				<header id="site-header" className="container" role="banner">
					<a id="site-title" className="h1" href="/"> <img src={this.data.cubsLogo.url} alt = "site-header"/></a>
					<Image introBackgrounds={this.data.homePage.backgroundImage}/>
					<NavBar NavBar={this.data.mainNavigation.primaryNavigationLinks} />
					<div className="header-content">
						<div className="row padding-bottom">
							<div className="col sm-12 md-6 header-content-left">
								{this.data.homePage.featuredTweet && <Tweet tweet = {this.data.homePage.featuredTweet}/>}
							</div>
							<div className="col sm-12 md-6 header-content-right">
								<Weather weather = {this.data.homePage}/>
								<Buttons buttons={this.data.homePage}/>
							</div>
						</div>
					</div>
				</header>
				<main className="container">
					<News news={this}/>
					<Gallery gallery={this}/>
					{tempEvents.length > 0 && <Events events={this}/>}
					<Mission mission={this.data.homePage.missionComponent}/>
					{this.data.homePage.valuesImage && <ValueImage image={this.data.homePage}/>}
					<Values values={this.data.homePage}/>
				</main>
				<Footer footer={this.data.mainNavigation}/>
			</div>
		);
		
		
	}

}

export default Home;