import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import {gql} from '@apollo/client';

export default function searchWithData(WrappedComponent, options = {}) {
	const HOC = class extends Component {
		constructor(props) {
			super(props);
			this.wrapped = graphql(gql `query searchPageQuery {
				genericPageWithNews {
				  route
				  quickLinks {
				      route
				      text
				    }
				    popularDocuments {
				      fileLink{
		                  title
		                  description
		                  url
		                }
				      text
						}
				}
				genericPageListingOfFiles {
					route
			  	filesAndDocuments {
		            department
		            team
		            title
		            uploadDate
		            fileUpload {
		              url
		            }
		          }
		      }
				searchPages {
			      backgroundImage {
			        title
			        description
			        url
			      }
			      route
			    }
				eventItems {
				  eventTitle
				  route
				  eventDate
				  eventLocation
				  descriptionHeader
				  eventDescription
		          eventImage {
		            title
		            description
		            url
		          }
		      }
				newsItems {
			            newsTitle
						route
			            date
			            description
			            department{
			            	name
			            	route
			            }
			              image {
							    title
							    description
							    url
							}
					}
  exploreComponents {
					    isNotDisplayed
					    exploreTitle
					    exploreTile {
					    backgroundImage {
						        title
						        description
						        url
						      }
					      route
					      title
					      ctaText
					    }
					  }
			   globalSites{
			   	defaultImage {
						   title
						   description
						   url
						 }
					feedback {
						 feedbackHeader
						 feedbackDescription
						 feedbackCtAtext
					   feedbackImage {
						 title
						 description
						 url
						}
					}
				  mainNavigation {
						 footerHeader
						 footerBody
						 copyrightHolder
						 primaryNavigationLinks{
							 route
							 text
						 }
						 footerLinks{
						   route
						   text
						 }
						 secondaryFooterLinks {
						   route
						   text
						 }
						 footerLogo {
						   title
						   description
						   url
						 }
				   }
				   cubsLogo{
		              title
		              url
		            }
			  }
		  }
	  `, { name: 'searchPageQuery' })(WrappedComponent);
		}

		render() {
			const Wrapped = this.wrapped;
			return <Wrapped {...this.props}/>;

		}
	};

	return HOC;
}