import React, { Component } from 'react';

function IsEmpty(executive){
	if (executive.executive.executive != null){
		return <Executive executive = {executive.executive.executive}/>;
	}
	return <div/>;
}

function Executive(executive){
	return  (	<div className="col sm-12 md-5 department-leader">
		<div className="row">
			<div className="col sm-6">
				<img src = {executive.executive.picture.url} alt = "exec"/>
			</div>
			<div className="sol sm-6 leader-info">
				<h2> {executive.executive.name}</h2>
				<span> {executive.executive.title}</span>
			</div>
		</div>
	</div>);
}

class imageDescription extends Component{

	render(){
		const description = this.props.description;
		if (!description)
			return <div className="u-hidden"> Waiting</div>;
		return(
			<div>
				<div className="col sm-12 md-6 header-content-left2 interior-header-content-left">
					<h2> {description.backgroundHeader}</h2>
					<p>{description.backgroundDescription} </p>
				</div>
				<IsEmpty executive={this.props.description}/>
			</div>
		);

	}
}

export default imageDescription;