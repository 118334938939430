import React, { Component } from 'react';

class Footer extends Component{

	render(){
		const footer = this.props.footer;

		return(
			<footer id="site-footer" className="container" role="contentinfo">
				<div className="row">
					<div className="col md-12">
						<div className="footer-logo"></div>
						<div className="footer-content">
							<div className="copyright">
								<small></small>
								<small>{footer.footerHeader}</small>
							</div>
							<nav className="footer-nav">
								<h2 className="assistive-text">Footer Navigation</h2>
								<ul>
									<ul>
										{footer.footerLinks.map((link,index) => {
											return(
												<li key={index}>
													<a href={link.route}>{link.text}</a>
												</li>
												);
										})}
									</ul>
								</ul>
								<ul className="secondary-footer-nav">
									<ul>
										{footer.secondaryFooterLinks.map((link, index) => {
											return(
												<li key={index} className="external">
													<a href={link.route}>{link.text}</a>
												</li>
												);
										})}
									</ul>
								</ul>
							</nav>
						</div>
					</div>
				</div>
			</footer>

		);
	}
}

export default Footer;