import React, { Component } from 'react';

function QuickLinks(links){
	return (<ul className="quick-links"> 
		{links.links.map(function(link,index){
			if(link.fileLink){
				return (<li key = {index}>
					<a href= {link.fileLink.url}> {link.text} </a>
				</li>);
			} else {
				return (<li key = {index}>
					<a href= {link.route}> {link.text} </a>
				</li>);
			}
		})}
	</ul>);
}

class Buttons extends Component{

	render(){
		const buttons = this.props.buttons;
		return(
			<div className="row"> 
				<a href={buttons.externalLinks[0].route} className="col sm-6 md-4 header-widget no-border-right widget-link">
					<span className="widget-icon-tickets"/>
					<span className="widget-tickets widget-text">
						{buttons.externalLinks[0].text}
					</span>
				</a>
				<a href={buttons.externalLinks[1].route} className= "col sm-6 md-4 header-widget no-border-right border-right widget-link">
					<span className="widget-icon-stats"/>
					<span className="widget-stats widget text">
						{buttons.externalLinks[1].text}
					</span>
				</a>
				<div className="col sm-12 md-4 header-widget">
					<span className="quick-links-header">Quick Links </span>
					<QuickLinks links = {buttons.quickLinks}/>
				</div>
			</div>
		);

	}
}

export default Buttons;