import React, { Component } from 'react';
import {Tab, Tabs, TabPanel, TabList } from 'react-web-tabs';
 
class Values extends Component{

	render(){
		const values = this.props.values;
		return(
			<section className="section values-section">
				<div className="section-content values">
					<div className="row values-row">
						<h2 className="section-title">{values.valueHeader}</h2>					
						<Tabs defaultTab="vertical-tab-one" vertical>
							<TabList  >
								<Tab focusable tabFor="vertical-tab-one" ><span className="red-icon"> {values.valueSection[0].valueHeader}</span></Tab>
								<Tab focusable tabFor="vertical-tab-two"><span className="red-icon"> {values.valueSection[1].valueHeader}</span></Tab>
								<Tab focusable tabFor="vertical-tab-three"><span className="red-icon"> {values.valueSection[2].valueHeader}</span></Tab>
								<Tab focusable tabFor="vertical-tab-four"><span className="red-icon"> {values.valueSection[3].valueHeader}</span></Tab>
								<Tab focusable tabFor="vertical-tab-five"><span className="red-icon"> {values.valueSection[4].valueHeader}</span></Tab>
							</TabList>
							<div className="resp-tabs-container">
								<TabPanel tabId="vertical-tab-one">
									<span className="accordian-content">
										<img src={values.valueSection[0].valueIcon.url} alt="inclusive"/>
										<h2 className="panel-title inclusive"> {values.valueSection[0].valueHeader} </h2>
										<p>{values.valueSection[0].valueDescription}</p>
									</span>
								</TabPanel>
								<TabPanel tabId="vertical-tab-two">
									<span className="accordian-content">
										<img src={values.valueSection[1].valueIcon.url} alt="critical thinker"/>
										<h2 className="panel-title inclusive"> {values.valueSection[1].valueHeader} </h2>
										<p>{values.valueSection[1].valueDescription}</p>
									</span>
								</TabPanel>
								<TabPanel tabId="vertical-tab-three">
									<span className="accordian-content">
										<img src={values.valueSection[2].valueIcon.url} alt="service focused"/>
										<h2 className="panel-title inclusive"> {values.valueSection[2].valueHeader} </h2>
										<p>{values.valueSection[2].valueDescription}</p>
									</span>
								</TabPanel>
								<TabPanel tabId="vertical-tab-four">
									<span className="accordian-content">
										<img src={values.valueSection[3].valueIcon.url} alt="service focused"/>
										<h2 className="panel-title inclusive"> {values.valueSection[3].valueHeader} </h2>
										<p>{values.valueSection[3].valueDescription}</p>
									</span>
								</TabPanel>
								<TabPanel tabId="vertical-tab-five">
									<span className="accordian-content">
										<img src={values.valueSection[4].valueIcon.url} alt="service focused"/>
										<h2 className="panel-title inclusive"> {values.valueSection[4].valueHeader} </h2>
										<p>{values.valueSection[4].valueDescription}</p>
									</span>
								</TabPanel>
							</div>
						</Tabs>
					</div>
				</div>
			</section>
		);
	}
}

export default Values;