import React, { Component } from 'react';

class imageDescription extends Component{

	render(){
		const description = this.props.description;
		return(
			<div className="col sm-12 md-6 header-content-left2 interior-header-content-left">
				<h2> {description.backgroundHeader}</h2>
				<p>{description.backgroundDescription} </p>
			</div>
		);

	}
}

export default imageDescription;