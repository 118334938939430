import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import classnames from 'classnames';

import ogImg from '../../assets/img/og.jpg';
import Explore from '../../components/shared/explore.js';
import Footer from '../../components/shared/Footer';
import NavBar from '../../components/shared/NavBar';
import Description from '../../components/shared/imageDescription';

const style = { width: 700,
	height: 700,
	border: null,
};

class Signature extends Component { 
	constructor(props) {
		super(props);
		this.onTransitionEnd.bind(this);
	}
	state = {
		transitionEnded: false,
	};
	onTransitionEnd = () => {
		this.setState({ transitionEnded: true }); 
	};

	render() {
		const heroClasses = classnames('hero', {
			animate: this.state.transitionEnded
		});

		const h2Class = {
			marginTop: '100px',
			color: 'black',
			textAlign: 'center',
			fontWeight: 'bold',
			textDecoration: 'underline'
		}
		//if this.props.signaturePageQuery is null wait, if not null check state of signaturePageQuery until done loading
		if (!this.props.signaturePageQuery){
			return <div className="u-hidden"> Waiting</div>;
		}

		else if (this.props.signaturePageQuery && this.props.signaturePageQuery.loading) { 
			return <div className="u-hidden">Loading</div>; 
		}

		else if (this.props.signaturePageQuery && this.props.signaturePageQuery.error) {
			return <div className="u-hidden">Error </div>;
		}

		//done loading
		this.data = this.props.signaturePageQuery.globalSites[0];

		if (!this.data) {
			window.location.href = '/404';
			return null;
		} 
		return(
			<div  key="home" className={heroClasses}>   
				<Helmet>
					<meta property="og:image" content={ogImg}/>
					<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"/>
				</Helmet>
				<header id="site-header" className="container" role="banner">
					<a id="site-title" className="h1" href="/"> <img src={this.data.cubsLogo.url} alt = "site-header"/></a>
					<NavBar NavBar={this.data.mainNavigation.primaryNavigationLinks} />
					<div className="header-content">
						<div className="row2 padding-bottom">
							<h2 style={h2Class}>{this.props.signaturePageQuery.genericPageWithoutBackground.backgroundHeader}</h2>
						</div>
					</div>
					{this.props.signaturePageQuery.genericPageWithoutBackground.sourceUrl && 
						<iframe src={this.props.signaturePageQuery.genericPageWithoutBackground.sourceUrl} title="signature script"className="centerSig" style={style}>
							<p>Your browser does not support iframes.</p>
						</iframe>
					}
					<div className="centerSig">
						{this.props.signaturePageQuery.genericPageWithoutBackground.content &&
							this.props.signaturePageQuery.genericPageWithoutBackground.content.map((c, i) => {
								return(
									<a key={i} href={c.route}>{c.text}</a>
								)
							})
						}
					</div>
				</header>
				<main className="container">
					<Explore explore={this.props.signaturePageQuery.exploreComponents[0]}/>
				</main>
				<Footer footer={this.data.mainNavigation}/>
			</div>
		);
		
		
	}

}

export default Signature;