import React, { Component } from 'react';
import Moment from 'moment';
import galleryWithData from './galleryWithData';
import QueryGallery from './galleryItem';
import FeatureItem from './galleryFeaturedItem';
import ReactMarkdown from 'react-markdown';
import remarkBreaks from 'remark-breaks';
import rehypeRaw from 'rehype-raw';
						
class Gallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedMonth: '',
      selectedYear: '',
      selectedGroup: '',
    };
    this.handleMonthChange = this.handleMonthChange.bind(this);
    this.handleYearChange = this.handleYearChange.bind(this);
    this.handleGroupChange = this.handleGroupChange.bind(this);
  }

  handleMonthChange(event) {
    this.setState({ selectedMonth: event.target.value });
  }

  handleYearChange(event) {
    this.setState({ selectedYear: event.target.value });
  }

  handleGroupChange(event) {
    this.setState({ selectedGroup: event.target.value });
  }

  render() {
    const { gallery } = this.props;
    const featuredGalleryItems =[];
    const noShowDetails = [];
    const galleryGroups = gallery?.galleryGroups || [];
    const { selectedMonth, selectedYear, selectedGroup } = this.state;
    const months = Moment.months().map((month, index) => (
            <option key={index} value={index}>{month}</option>
        ));
    const years = Array.from({length: 2}, (_, i) => new Date().getFullYear() - i);
    const yearOptions = years.map((year, index) => (
        <option key={index} value={year}>{year}</option>
    ));



    const filteredGalleryItems = galleryGroups
      .map((group) => {
        const items = group.galleryItems || [];
        // Sort items by release date (newest first)

        items.sort((a, b) => {
          return new Date(b.releaseDate) - new Date(a.releaseDate);
        });

        const featuredItems = items.filter((item) => item.featuredVideo === true);
        if (featuredItems.length > 0) {
          featuredGalleryItems.push(featuredItems[0]);
          noShowDetails.push(group.name);
        }

        return { groupName: group.name, items };
      });

    const uniqueGroupNames = [...new Set(galleryGroups.map((group) => group.name))];


    filteredGalleryItems.forEach((item) => {
        if (item.items.length === 1 && item.items[0].featuredVideo === true) {
            const indexToRemove = uniqueGroupNames.indexOf(item.groupName);
            if (indexToRemove !== -1) {
                uniqueGroupNames.splice(indexToRemove, 1);
                }
            }
    });

    const nameOptions = uniqueGroupNames.map((groupName, index) => (
        <option key={index} value={groupName}>{groupName}</option>
    ));

    const filteredGalleryGroups = galleryGroups.filter((group) =>
      group.name.toLowerCase().includes(selectedGroup.toLowerCase())
    );

    console.log(gallery)
    return (
        <section className="section">
        	<div className="container-fluid">
                {gallery.pageDescription && 
                    <div className="rowcustom padding-bottom">
                        <ReactMarkdown className = "black additionalInformation padding-bottom" remarkPlugins={[remarkBreaks]} rehypePlugins={[rehypeRaw]}>
                            {gallery.pageDescription}
                         </ReactMarkdown>
                    </div>
                }
                {!gallery.hideFilters &&
                    <header className="section-header ">
                    	<div className="col">
                            <h3 className="section-title row news-layout"> Filters</h3>
                        </div>
                       {/* <div className="col">
                            <h2 className="section-title row news-layout"> {news.newsPages[0].newsHeader}</h2>
                        </div>*/}
                    </header>
                }
                <div className="row news-layout">
                    {!gallery.hideFilters  &&
                    	<div className="col md-2 ">
                    	    <div className="select-month-year">
                                <div className="select-year">
                                    <select value={this.state.selectedGroup} onChange={this.handleGroupChange}>
                            			<option value="">Select Group</option>
                                		{nameOptions}
                          			</select>
                                </div>
                                <div className="select-year">
                                    <select value={this.state.selectedYear} onChange={this.handleYearChange}>
                                        <option value="">Select Year</option>
                                        {yearOptions}
                                    </select>
                                </div>
                                <div className="select-month">
                                    <select disabled={!this.state.selectedYear} value={this.state.selectedMonth} onChange={this.handleMonthChange}>
                                        <option value="">Select Month</option>
                                        {months}
                                    </select>
                                </div>
                            </div>
                		</div>
                    }
            		<div className="col md-10 gallery-item-list">
                        <FeatureItem featureItem={featuredGalleryItems} />
                      	{filteredGalleryItems.map((group, groupIndex) => (
                                noShowDetails.includes(group.groupName) && group.items.length === 1 ? null : (
                                    <div key={groupIndex} className="gallery-group">
                                    <h3 className="section-title row news-layout">{group.groupName}</h3>
                                    <QueryGallery gallery={group} selectedMonth={this.state.selectedMonth} selectedYear={this.state.selectedYear} selectedGroup={this.state.selectedGroup} length={group.items.length} />
                            </div>)
                        ))}
                    </div>
            	</div>
                {gallery.additionalInformation && 
                    <div className="rowcustom padding-top padding-bottom">
                        <ReactMarkdown className = "black additionalInformation " remarkPlugins={[remarkBreaks]} rehypePlugins={[rehypeRaw]}>{gallery.additionalInformation}</ReactMarkdown>
                    </div>
                }
        	</div>
        </section>
    );

  }
}

export default Gallery;

