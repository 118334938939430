import React, {Component} from 'react';
import './styles/main.css';

import Home from './pages/home/Home';
import Router from './Router';

class App extends Component {
	componentDidMount() {
		if (this.pathIsNotHomepage()) {
			sessionStorage.setItem('cachedHit', true);
		}
	}

	pathIsNotHomepage = () => {
		return window.location.pathname !== '/';
	}

	render() {
		return (
			[
				<Home key = "Home"/>,
				<Router key={this.state}/>
			]
		);
	}
}

export default App;
