import React, { Component } from 'react';
import queryString from 'query-string';
import _ from 'underscore';

function SearchAssociates(obj){
	let temp = _.filter(obj.obj.associates, function(x){ if (x.associate != null && obj.obj.filter != null) return x.associate.toLowerCase().includes(obj.obj.filter.toLowerCase())});
	return (<div className= "section-content cubs-results">
		<div className = "text black">
			<h1 className="black"> <em>{temp.length} </em> associates found 
				<span className="search-term black">
					<span> for </span>"
					{obj.obj.filter}"
				</span>
			</h1>
			{temp.map(function(associate, index){
				return(<ol key={index}>
					<h2 className = "black"> {temp[index].associate}</h2>
					<div className="text-meta" role="contentinfo">
						<ul className = "text-data black" >
							<li key={index + '1'}> {temp[index].title}</li>
							<li key={index + '2'}> {temp[index].phone}</li>
							<li key={index + '3'}> {temp[index].email}</li>
						</ul>
					</div>
				</ol>
				);
			})}
		</div>
	</div>);
}

function SearchFiles(file){
	if (file.file.filter == null){
		return (<div className= "section-content cubs-results">
			<div className = "text black">
				<h1 className="black"> <em>0 </em> search results 
					<span className="search-term black">
						<span> for </span>""
					</span>
				</h1>
			</div>
		</div>);
	}

	let temp = [];
	let events = _.filter(file.file.events, function(x){
		let y = Object.values(x); 
		for (let i = 0; i < y.length; i++){
			if (typeof y[i] !== 'object') {
				if( y[i].toLowerCase().includes(file.file.filter.toLowerCase())) return true;
			}
		}
	});

	temp = temp.concat(events);

	let news =_.filter(file.file.news, function(x){
		let y = Object.values(x); 
		for (let i = 0; i < y.length; i++){
			if (typeof y[i] !== 'object') {
				if( y[i].toLowerCase().includes(file.file.filter.toLowerCase())) return true;
			}
		}
	});

	temp = temp.concat(news);

	let lists = _.filter(file.file.files, function(x){
		if( x.route.replace( /-/g,' ').toLowerCase().includes(file.file.filter.toLowerCase())) return true;
	});
	
	temp = temp.concat(lists);
	

	for (let j = 0; j < 3; j++){
		let doc = _.filter(file.file.files[j].filesAndDocuments, function(x){
			let y = Object.values(x); 
			for (let i = 0; i < y.length; i++){
				if (typeof y[i] !== 'object') {
					if( y[i].toLowerCase().includes(file.file.filter.toLowerCase())) return true;
				}
			}

		}
		);
		
		temp = temp.concat(doc);
	}

	for (let j = 0; j < file.file.pages.length; j++){
		let quick = _.filter(file.file.pages[j].quickLinks, function(x){
			let y = Object.values(x); 
			for (let i = 0; i < y.length; i++){
				if (typeof y[i] !== 'object') {
					if( y[i].toLowerCase().includes(file.file.filter.toLowerCase())) return true;
				}
			}

		});
		let pop = _.filter(file.file.pages[j].popularDocuments, function(x){
			let y = Object.values(x); 
			for (let i = 0; i < y.length; i++){
				if (typeof y[i] !== 'object') {
					if( y[i].toLowerCase().includes(file.file.filter.toLowerCase())) return true;
				}
			}

		});
		temp = temp.concat(quick, pop);
	}

	let dept = _.filter(file.file.pages, function(x){
		if( x.route.replace( /-/g,' ').toLowerCase().includes(file.file.filter.toLowerCase())) return true;
	}
	);
	temp = temp.concat(dept);
	return (<div className= "section-content cubs-results">
		<div className = "text black">
			<h1 className="black"> <em>{temp.length} </em> search reuslts 
				<span className="search-term black">
					<span> for </span>"
					{file.file.filter}"
				</span>
			</h1>
			{temp.map(function(object, index){
				if (temp[index].__typename === 'GenericPageWithNews'){
					return(<ol key={index}>
						<h2 className = "black"> <a href={'/departments/'+temp[index].route}>{temp[index].route.replace( /-/g,' ').toLowerCase()}</a></h2>
						<div className="text-meta" role="contentinfo">
							<ul className = "text-data black">
								<li key={index + '1'}> Category: Department</li>
							</ul>
						</div>
					</ol>
					);
				}
				if (temp[index].__typename === 'GenericPageListingOfFiles'){
					return(<ol key={index}>
						<h2 className = "black"> <a href={temp[index].route}>{temp[index].route.replace( /-/g,' ').toLowerCase()}</a></h2>
						<div className="text-meta" role="contentinfo">
							<ul className = "text-data black">
								<li key={index + '1'}> Category: Pages</li>
							</ul>
						</div>
					</ol>
					);
				}
				if (temp[index].__typename === 'EventItem'){
					return(<ol key={index}>
						<h2 className = "black"> <a href={'/events/'+ (temp[index].route) + '/' + temp[index].eventDate}>{temp[index].eventTitle}</a></h2>
						<div className="text-meta" role="contentinfo">
							<ul className = "text-data black">
								<li key={index + '1'}> {temp[index].eventDate}</li>
								<li key={index + '2'}> Category: Event</li>
							</ul>
						</div>
					</ol>
					);}
				if (temp[index].__typename === 'NewsItem'){
					return(<ol key={index}>
						<h2 className = "black"> <a href={'/cubs-news/'+ (temp[index].route)}>{temp[index].newsTitle}</a></h2>
						<div className="text-meta" role="contentinfo">
							<ul className = "text-data black">
								<li key={index + '1'}> {temp[index].date}</li>
								<li key={index + '2'}> Category: News</li>
							 </ul>
						</div>
					</ol>
					);
				}
				if (temp[index].__typename === 'FilesAndDocuments'){
					return(<ol key={index}>
						<h2 className = "black"> <a href={temp[index].fileUpload.url}>{temp[index].title}</a></h2>
						<div className="text-meta" role="contentinfo">
							<ul className = "text-data black">
								<li key={index + '1'}> {temp[index].uploadDate}</li>
								<li key={index + '2'}> Category: File</li>
							</ul>
						</div>
					</ol>
					);
				}
				if (temp[index].__typename === 'LinkComponent' && temp[index].fileLink == null){
					return(<ol key={index}>
						<h2 className = "black"> <a href={temp[index].route}>{temp[index].text}</a></h2>
						<div className="text-meta" role="contentinfo">
							<ul className = "text-data black">
								<li key={index + '1'}> Category: File</li>
							</ul>
						</div>
					</ol>
					);
				}
				if (temp[index].__typename === 'LinkComponent'){
					return(<ol key={index}>
						<h2 className = "black"> <a href={temp[index].fileLink.url}>{temp[index].text}</a></h2>
						<div className="text-meta" role="contentinfo">
							<ul className = "text-data black">
								<li key={index + '1'}> Category: File</li>
							</ul>
						</div>
					</ol>
					);
				}
				return <div/>;
			})}
		</div>
	</div>);
}

class search extends Component{
	constructor(props){
		super(props);
		this.state = {dataloaded: false, 
			filter: '',
			associates: [],
			events: this.props.search.props.searchPageQuery.eventItems,
			news: this.props.search.props.searchPageQuery.newsItems,
			files: this.props.search.props.searchPageQuery.genericPageListingOfFiles,
			pages: this.props.search.props.searchPageQuery.genericPageWithNews
		};

		this.componentDidMount = this.componentDidMount.bind(this);
	}

	componentDidMount(){
		const value = queryString.parse(this.props.search.props.location.search);
		this.setState({filter: value.queryStr});
		fetch('/employee', {
			headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
		})
			.then(response => response.json())
			.then(associate => {
				associate.associates.sort(function(a, b){
					if(a.associate < b.associate) { return -1; }
					if(a.associate > b.associate) { return 1; }
					return 0;
				});

				this.setState({associates: associate.associates});
				this.setState({dataloaded: true});
			});

	}

	

	render(){
		let dataLoaded = this.state.dataloaded;
		let json = this.state;
		if (dataLoaded === false){
			return <div> Loading... </div>;
		}
		return(
			<section className="search-results">
				<div className="row">
					<div className= "col md-6">
						<div className= "section-header">
							<h2 className="section-title"> Cubs Results </h2>
						</div>
						<div className= "section-content cubs-results" onLoad= {this.componentDidMount}>
							<SearchFiles file = {json}/>
						</div>
					</div>
					<div className= "col md-6">
						<div className= "section-header">
							<h2 className="section-title"> Associate Search Results </h2>
						</div>
						<div className= "section-content cubs-results">
							<SearchAssociates obj = {json}/>
						</div>
					</div>
				</div>
			</section>
		);

	}
}

export default search;