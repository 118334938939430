import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import {gql} from '@apollo/client';
import { withRouter } from 'react-router-dom';

export default function genericWithData(WrappedComponent, options = {}) {
	const HOC = class extends Component {
		constructor(props) {
			super(props);
			this.wrapped = graphql(gql `query genericPageQuery {
			  genericPageBoxes {
			    backgroundHeader
			    backgroundDescription
			    backgroundImage {
			      title
			      description
			      url
			    }
			    route
			    link {
			    	image{
			    		title
			    		url
			    		description
			    	}
			      route
			      text
			    }
			  }
			  feedbackComponents {
			    feedbackHeader
			    feedbackDescription
			    feedbackCtAtext
			    feedbackImage {
			      title
			      description
			      url
			    }
			  }
			  headerFooterComponents {
			    footerHeader
			    footerBody
			    copyrightHolder
			    primaryNavigationLinks{
			      route
			      text
			    }
			    footerLinks{
			      route
			      text
			    }
			    secondaryFooterLinks {
			      route
			      text
			    }
			    footerLogo {
			      title
			      description
			      url
			    }
			  }
					  exploreComponents {
					    isNotDisplayed
					    exploreTitle
					    exploreTile {
					    backgroundImage {
						        title
						        description
						        url
						      }
					      route
					      title
					      ctaText
					    }
					  }
			  globalSites{feedback {
						 feedbackHeader
						 feedbackDescription
						 feedbackCtAtext
					   feedbackImage {
						 title
						 description
						 url
						}
					}
				  mainNavigation {
						 footerHeader
						 footerBody
						 copyrightHolder
						 primaryNavigationLinks{
							 route
							 text
						 }
						 footerLinks{
						   route
						   text
						 }
						 secondaryFooterLinks {
						   route
						   text
						 }
						 footerLogo {
						   title
						   description
						   url
						 }
				   }
				   cubsLogo{
		              title
		              url
		            }
			  }
}
	  `, { name: 'genericPageQuery' })(WrappedComponent);
		}

		render() {
			const Wrapped = this.wrapped;
			return <Wrapped {...this.props}/>;

		}
	};

	return withRouter(HOC);
}