import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import classnames from 'classnames';

import ogImg from '../../assets/img/og.jpg';
import Image from '../../components/home/Image';
import SearchData from '../../components/search/search';
import Explore from '../../components/shared/explore.js';
import Footer from '../../components/shared/Footer';
import NavBar from '../../components/shared/NavBar';



class Search extends Component { 
	constructor(props) {
		super(props);
		this.onTransitionEnd.bind(this);
	}
	state = {
		transitionEnded: false,
	};
	onTransitionEnd = () => {
		this.setState({ transitionEnded: true }); 
	};

	render() {
		const heroClasses = classnames('hero', {
			animate: this.state.transitionEnded
		});
		//if this.props.searchPageQuery is null wait, if not null check state of searchPageQuery until done loading
		if (!this.props.searchPageQuery){
			return <div className="u-hidden"> Waiting</div>;
		}

		else if (this.props.searchPageQuery && this.props.searchPageQuery.loading) { 
			return <div className="u-hidden">Loading</div>; 
		}

		else if (this.props.searchPageQuery && this.props.searchPageQuery.error) {
			return <div className="u-hidden">Error </div>;
		}

		//done loading
		this.data = this.props.searchPageQuery.globalSites[0];
		if (!this.data) {
			window.location.href = '/404';
			return null;
		} 
		
		return(
			<div  key="home" className={heroClasses}>   
				<Helmet>
					<meta property="og:image" content={ogImg}/>
					<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"/>
				</Helmet>
				<header id="site-header" className="container" role="banner">
					<a id="site-title" className="h1" href="/"> <img src={this.data.cubsLogo.url} alt = "site-header"/></a>
					<Image introBackgrounds={this.props.searchPageQuery.searchPages[0].backgroundImage}/>
					<NavBar NavBar={this.data.mainNavigation.primaryNavigationLinks} />
				</header>
				<main className="container">
					<SearchData search={this}/>
					<Explore explore={this.props.searchPageQuery.exploreComponents[0]}/>
				</main>
				<Footer footer={this.data.mainNavigation}/>
			</div>
		);
		
		
	}

}

export default Search;