import React, { Component } from 'react';


class calendar extends Component{
	constructor(props){
		super(props);
		this.state = {events: [], dataLoaded: false};
	}

	render(){
		//const calendar = this.props.calendar;
		//console.log(this);
		return(
			<div>
				
			</div>
		);

	}

}

export default calendar;