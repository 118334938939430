import React, { Component } from 'react';
import ReactCrop from 'react-image-crop';

let crop = {
	width: 100,
	height: 62,

};

class box extends Component{


	render(){
		const box = this.props.box;
		return(
			<section className="section main-departments">
				<div className="section-content row department-items">
					<div className="col sm-12">
						<ul className="flex-container department-list">
							{this.props.box.link.map(function(exec,index){
								if (box.link[index].image != null)
									return <li key={index}>
										<a className="btn btn-primary" href={box.link[index].route}>
											<span><ReactCrop disabled={true} src={box.link[index].image.url} crop={crop} className="top" onChange={function(){return;}}/>
												<figcaption className = 'center-text top2'>{box.link[index].text} </figcaption>
											</span>
										</a>
									</li>;
								else
									return 	<li>
												<a className="btn btn-primary" href={box.link[index].route}>
													<span><ReactCrop disabled={true} crop={crop} className="top" onChange={function(){return;}}/>
														<figcaption className = 'center-text top2'>{box.link[index].text} </figcaption>
													</span>
												</a>
											</li>;
							})}
						</ul>
					</div>
				</div>
			</section>
		);

	}
}

export default box;