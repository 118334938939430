import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import {gql} from '@apollo/client';

export default function signatureWithData(WrappedComponent, options = {}) {
	const HOC = class extends Component {
		constructor(props) {
			super(props);
			this.wrapped = graphql(gql `query signaturePageQuery {
					genericPageWithoutBackground(id: "1KWMBiJaAORhEZgrc7Na7U"){
						sourceUrl
						backgroundHeader
						backgroundDescription
						content {
							route
							text
						}
					}
  					exploreComponents {
					    isNotDisplayed
					    exploreTitle
					    exploreTile {
					    backgroundImage {
						        title
						        description
						        url
						      }
					      route
					      title
					      ctaText
					    }
					  }
			   globalSites{
			   	defaultImage {
						   title
						   description
						   url
						 }
					feedback {
						 feedbackHeader
						 feedbackDescription
						 feedbackCtAtext
					   feedbackImage {
						 title
						 description
						 url
						}
					}
				  mainNavigation {
						 footerHeader
						 footerBody
						 copyrightHolder
						 primaryNavigationLinks{
							 route
							 text
						 }
						 footerLinks{
						   route
						   text
						 }
						 secondaryFooterLinks {
						   route
						   text
						 }
						 footerLogo {
						   title
						   description
						   url
						 }
				   }
				   cubsLogo{
		              title
		              url
		            }
			  }
		  }
	  `, { name: 'signaturePageQuery' })(WrappedComponent);
		}

		render() {
			const Wrapped = this.wrapped;
			return <Wrapped {...this.props}/>;

		}
	};

	return HOC;
}