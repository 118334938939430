import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import classnames from 'classnames';
import {withRouter } from 'react-router-dom';

import ogImg from '../../assets/img/og.jpg';

import Footer from '../../components/shared/Footer';
import NavBar from '../../components/shared/NavBar';
import Explore from '../../components/shared/explore.js';
import Gallery from '../../components/gallery/gallery.js';
import Description from '../../components/shared/imageDescription';



class galleryPages extends Component { 
	constructor(props) {
		super(props);
		this.onTransitionEnd.bind(this);
	}
	state = {
		transitionEnded: false,
	};
	onTransitionEnd = () => {
		this.setState({ transitionEnded: true }); 
	};

	render() {
		const heroClasses = classnames('hero', {
			animate: this.state.transitionEnded
		});
		//if this.props.galleryPageQuery is null wait, if not null check state of homePageQuery until done loading
		if (!this.props.galleryPageQuery){
			return <div className="u-hidden">Waiting</div>;
		}

		else if (this.props.galleryPageQuery && this.props.galleryPageQuery.loading) { 
			return <div className="u-hidden">Loading</div>; 
		}

		else if (this.props.galleryPageQuery && this.props.galleryPageQuery.error) {
			return <div className="u-hidden">Error</div>;
		}


		//done loading
		let index;
		let length = this.props.galleryPageQuery.galleryPages.length;
		let path = this.props.location.pathname;
		// probably not needed any longer since GraphQL query filters for routes
		for (let i = 0; i < length; i++){
			let title = this.props.galleryPageQuery.galleryPages[i].newsTitle;
			let route = this.props.galleryPageQuery.galleryPages[i].route;
			if (route === route ){
				index = i;
			}
		}

		this.data = this.props.galleryPageQuery;
		if (!this.data) {
			window.location.href = '/404';
			return null;
		}

		return(
			<div id="home">
				<div key="interior news-detail" className={heroClasses}>   
					<p className='hidden'> news: {this.props.location.pathname}</p>
					<Helmet>
						<meta property="og:image" content={ogImg}/>
						<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"/>
					</Helmet>
					<header id="site-header" className= "container" role="banner">
						<a id="site-title" className="h1" href="/"> <img src={this.data.globalSites[0].cubsLogo.url} alt = "site-header"/></a>
						<img src={this.data.galleryPages[0]?.backgroundImage.url} alt = "background" className="featured-image"/>
						<NavBar NavBar={this.data.globalSites[0].mainNavigation.primaryNavigationLinks}/>
						<div className="header-content">
							<div className="row2 padding-bottom">
								<Description description= {this.data.galleryPages[0]}/>
							</div>
						</div>
					</header>
					<main className="container">
						<Gallery gallery={this.data.galleryPages[index]} loading={this.props.loading}/>
						<Explore explore={this.data.exploreComponents[0]}/>
					</main>
					<Footer footer={this.data.globalSites[0].mainNavigation}/>
				</div>
			</div>
		);
		
		
	}

}

export default withRouter(galleryPages);