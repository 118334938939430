import React, { Component } from 'react';
let dateFormat = require('dateformat');

function Date(date){
	let temp = date.date;

	return <td data-th= "Date Modified"> {dateFormat(temp.uploadDate,'mmmm dd, yyyy')} </td>;


}

function Show(file){
	const temp = file.file;
	if (temp.fileAndDocumentsHeader !== null){
		return(
			<section className='section'>
				<header className='section-header row'>
					<div className='col'>
						<h2 className='section-title'>{temp.fileAndDocumentsHeader}</h2>
					</div>
				</header>
				<div className='section-content row cubs-spotlight'>
					<div className='col md-3 spotlight'>
						<form action='#' method='get' id='deptForm' noValidate='novalidate'>
							<fieldset className='form-group'>
								<legend className='assistive-text'>Departments</legend>
											
							</fieldset>
						</form>
					</div>
					<div className='col md-9 spotlight'>
						<table id='documentTable' className='display rwd-table'>
							<thead>
								<tr>
									<th>Name</th>
									<th>Department</th>
									<th>Date Modified</th>
								</tr>
							</thead>
							<Map map = {temp.filesAndDocuments}/>
						</table>
					</div>
				</div>
			</section>);
	}
	return <div/>;
}

function Map(map){
	let temp = map.map;
	return (
		<tbody>
			{temp.map(function(link,index)
			{
				return(
					<tr key={index}>
						<td data-th='Name'><a href={temp[index].fileUpload.url}>{temp[index].title}</a></td>
						<td data-th='Department'>{temp[index].department}</td>
						<Date date={temp[index]}/>
					</tr>);
			}
			)}
		</tbody>

	);


}

class filesAndDocuments extends Component{

	render(){
		const files = this.props.files;
		return(
			<Show file = {files}/>
		);

	}
}

export default filesAndDocuments;