import React, { Component } from 'react';

class backgroundImage extends Component{

	render(){
		const images = this.props.introBackgrounds;

		//console.log(this)
		if (!images)
			return <div className="u-hidden"> Waiting</div>;
		return(
			<div className="featured-image">
				<div className='blue-tint'/>
				<img src={images.backgroundImage.url} alt={images.backgroundImage.title} />
			</div>
		);

	}
}

export default backgroundImage;