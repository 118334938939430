import React, { Component } from 'react';
import DarkSkyApi from 'dark-sky-api';
import clear_day from '../../styles/img/weather/Sun.png';
import clear_night from '../../styles/img/weather/Moon.png';
import rain from '../../styles/img/weather/Cloud-Rain.png';
import lightning from '../../styles/img/weather/Cloud-Lightning.png';
import snow from '../../styles/img/weather/Cloud-Snow.png';
import sleet from '../../styles/img/weather/Cloud-Rain.png';
import wind from '../../styles/img/weather/Cloud-Fog.png';
import fog from '../../styles/img/weather/Cloud-Fog.png';
import cloudy from '../../styles/img/weather/Cloud.png';
import cloudy_day from '../../styles/img/weather/Cloud-Sun.png';
import cloudy_night from '../../styles/img/weather/Cloud-Moon.png';
import partly_cloudy from '../../styles/img/weather/Cloud.png';
import partly_cloudy_day from '../../styles/img/weather/Cloud-Sun.png';
import partly_cloudy_night from '../../styles/img/weather/Cloud-Moon.png';

class Weather extends Component{

	constructor(props) {
		super(props);
		this.state = {
			temp: '',
			description: '',
			precipitation: '',
			icon: '',
			wind: ''
		};

		this.componentDidMount = this.componentDidMount.bind(this);
		this.parseCondition = this.parseCondition.bind(this);
	}

	componentDidMount(){
		fetch('/weather', {
			headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
		})
		.then(response => response.json())
		.then(result => {	
			console.log(result);
			if(result.length > 0){
				const conditionResult = this.parseCondition(result[0].conditionCode, result[0].daylight)
				this.setState({
					temp: Math.floor(result[0].temperatureF),
					wind: Math.floor(result[0].windSpeedMPH),
					humidity: Math.floor(result[0].humidity*100),
					icon: conditionResult.icon,
					description: conditionResult.description,
				});		
			}	
		});
	}

	parseCondition(conditionCode, daylight){
		const isDay = daylight === 'true';
		switch(conditionCode.toLowerCase()) {
			case 'clear':
		  		return isDay ? {icon: clear_day, description: "Clear"} : {icon: clear_day, clear_night, description: "Clear"};
		  	case 'cloudy':
		  		return isDay ? {icon: cloudy_day, description: "Cloudy"} : {icon: cloudy_night, description: "Cloudy"};
		  	case 'foggy':
		  		return {icon: fog, description: "Foggy"};
		  	case 'haze':
		  		return {icon: fog, description: "Hazy"};
		 	case 'mostlyclear':
		  		return isDay ? {icon: clear_day, description: "Mostly Clear"} : {icon: clear_night, description: "Mostly Clear"};
		  	case 'mostlycloudy':
		  		return isDay ? {icon: cloudy_day, description: "Mostly Cloudy"} : {icon: cloudy_night, description: "Mostly Cloudy"};
		  	case 'partlycloudy':
		  		return isDay ? {icon: partly_cloudy_day, description: "Partly Cloudy"} : {icon: partly_cloudy_night, description: "Partly Cloudy"};
		  	case 'smoky':
		  		return {icon: fog, description: "Smoky"};
		  	case 'breezy':
		  		return {icon: wind, description: "Breezy"};
		  	case 'windy':
		  		return {icon: wind, description: "Windy"};
		  	case 'drizzle':
		  		return {icon: rain, description: "Drizzle"};
		  	case 'headyrain':
		  		return {icon: rain, description: "Heavy Rain"};
		  	case 'isolatedthunderstorms':
		  		return {icon: lightning, description: "Isolated Thunderstorms"};
		  	case 'rain':
		  		return {icon: rain, description: "Rain"};
		  	case 'sunshowers':
		  		return {icon: rain, description: "Sun Showers"};
		  	case 'scatteredthunderstorms':
		  		return {icon: lightning, description: "Scattered Thunderstorms"};
		  	case 'strongstorms':
		  		return {icon: lightning, description: "Strong Storms"};
		  	case 'thunderstorms':
		  		return {icon: lightning, description: "Thunderstorms"};
		  	case 'frigid':
		  		return {icon: snow, description: "Frigid"};
		  	case 'hail':
		  		return {icon: rain, description: "Hail"};
		  	case 'hot':
		  		return {icon: clear_day, description: "Hot"};
		  	case 'flurries':
		  		return {icon: snow, description: "Flurries"};
		  	case 'sleet':
		  		return {icon: rain, description: "Sleet"};
		  	case 'snow':
		  		return {icon: snow, description: "Snow"};
		  	case 'sunflurries':
		  		return {icon: snow, description: "Flurries"};
		  	case 'wintrymix':
		  		return {icon: snow, description: "Wintry Mix"};
		  	case 'blizzard':
		  		return {icon: snow, description: "Blizzard"};
		  	case 'blowingsnow':
		  		return {icon: snow, description: "Blowing Snow"};
		  	case 'freezingdrizzle':
		  		return {icon: rain, description: "Freezing Drizzle"};
		  	case 'freezingRain':
		  		return {icon: rain, description: "Freezing Rain"};
		  	case 'heavysnow':
		  		return {icon: snow, description: "Heavy Snow"};
		  	default:
		  		return isDay ? {icon: clear_day, description: conditionCode} : {icon: clear_night, description: conditionCode};
		}
	}

	render(){
		if(!this.state.icon){
			return(
				<div className="row">
					<div className="col-sm-12 md-8 header-widget no-border-bottom widget-right col-half">
						<div> 
							<div className="weather-content-container">
								<ul className="weather-stats">
									<li className="weather-outlook"><strong>Retrieving Current Weather...</strong></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			)
		}
		return(
			<div className="row">
				<div className="col-sm-12 md-8 header-widget no-border-bottom widget-right col-half">
					<div> 
						<div className="weather-content-container">
							<img src = {this.state.icon} alt="weather"/>
							<span className="weather-temp">{this.state.temp}<sup><strong>&#176;F</strong></sup></span>
							<ul className="weather-stats">
								<li className="weather-outlook"><strong>{this.state.description}</strong></li>
								<li className="weather-rain">Humidity&#58; {this.state.humidity}&#37;</li>
								<li className="weather-wind">Wind&#58;  {this.state.wind}mph</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Weather;