import React from 'react';
import Sidebar from 'react-sidebar';

function MapLinks(maps){
	let temp = maps.maps.NavBar;
	return (<ul> {temp.map(function(map,index){return <li key={index} className='nav nav-item white'> 
		<a href= {temp[index].route} style={styles.sidebarLink}>{temp[index].text}</a></li>;})}
	</ul>);
}

const styles = {
	sidebar: {
		background: '#242629',
		width: 256,
		height: '100%'
	},
	sidebarLink: {
		display: 'block',
		background: '#242629',
		padding: '5.7px 0px',
		color: '#ffffff',
		textDecoration: 'none',
		borderBottom: '1px solid white',
		index: '100'
	},
	divider: {
		margin: '8px 0',
		height: 1
	},
	content: {
		background: '#242629',
		padding: '16px',
		height: '100%'
	}
};

class NavBar extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			sidebarOpen: false
		};
		this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(event) {
		this.setState({value: event.target.value});
	}

	onSetSidebarOpen(open) {
		this.setState({ sidebarOpen: open });
	}

	render() {
		return (
			<div>
				<div className='app-bar'>
					<button size ="lg" onClick={() => this.onSetSidebarOpen(true)} >
						<span className='fa fa-bars fa-2x btn menu-bar'  >    <span className="title">Menu</span>    </span>
					</button>
					<div className= "row">
						<div className="col">
							<div id="search-box" className="search-bar">
								<form id="search-form" action="/search-results" >
									<div>
										<label htmlFor="query" className="assistive-text">Search</label>
										<input type="search" id="query" name="queryStr" placeholder="Find Employee/Search Site" value={this.state.value} onChange={this.handleChange} maxLength="150" className="black"/>
										<button className="btn btn-primary">
											<span className="fa fa-search white"></span>
											<span className="assistive-text">Go <span className="fa fa-caret-right"></span></span>
										</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
				<Sidebar
					sidebar={<div> <MapLinks maps= {this.props}/>
					</div>
					}
					open={this.state.sidebarOpen}
					onSetOpen={this.onSetSidebarOpen}
					styles={styles.sidebar}
				> </Sidebar>
			</div>
		);
	}
}
export default NavBar;
