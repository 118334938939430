import React, { Component } from 'react';
import everybodyIn from '../../img/Cubs_News_Bullseye_Final.jpg';
import Moment from 'moment';

function PlainTextToHtml({ text }) {
	console.log('test2')
	console.log(text)
  const regex = /<img.*?src="(.*?)".*?\/?>/g;
  const matches = [...text.matchAll(regex)];
  let html = text;
  matches.forEach(match => {
    const imgTag = `<img src="${match[1]}"/>`;
    html = html.replace(match[0], imgTag);
  });
  const urlRegex = /(https:\/\/chicagocubs\.(my\.salesforce|file\.force)\.com\/servlet\/servlet\.ImageServer\?id=.+?)(\s|$)/g;
  html = html.replace(urlRegex, '<img src="$1" width="100%" height="auto" />');
  /*html = html.replace(/style\s*=\s*(['"])(.*?)\1/gi, '');*/
  return <div dangerouslySetInnerHTML={{ __html: html }} />;
  console.log('test')
}




class newsTemplate extends Component{

	constructor(props) {
    super(props);
    this.listRef = React.createRef();
  }


  	componentDidMount() {
  		if(window.location.hash){
		    const element = document.getElementById(window.location.hash.replace('#',''));
		    
		    setTimeout(() => {
		    	element.scrollIntoView()
		    },600); 
	  };
  }

  componentDidUpdate(prevProps) {
    // Reassign the reference when the component updates
    if (prevProps.news[0].stories !== this.props.news[0].stories) {
      this.listRef = React.createRef();
    }


  }


	render(){
		const news = this.props.news[0];
		let date = Moment.utc(news.publish_date).format('MMMM DD, YYYY');
		return(
			<section className="section">
				<div className="section-content row news-detail-intro">
					<div className="col md-12">
						<h1> <b>{news.email_header}</b></h1>
						{/*<Image img={news}/>*/}
					</div>
					<div >
					{news.hero_image ? <PlainTextToHtml text={news.hero_image}/> : null}
					</div>
					<div className="col md-12">
						<br/>
						<div>
							<h1><b>{date}</b></h1>
						</div>
						<br/>
						<h1><b>In the News This Week...</b></h1>
						<ul>
							<ul ref={this.listRef}>
								{news.stories.map((link,index) => {
									return(
										<li key={index}>
											<a href={`#link-${index}`}>{link.subject}</a>
										</li>
										);
								})}
							</ul>
						</ul>
						<hr></hr>
						<ul>
							<ul>
								{news.stories.map((link,index) => {
									return(
										<li key={index} id={`link-${index}`}>
											<h3><b>{link.subject}</b></h3>
											<PlainTextToHtml text={link.body}/>
											<p></p>
										</li>
										);
								})}
							</ul>
						</ul>						
					</div>
					{/*<Video vid = {news}/>*/}
					<a href='/cubs-news'>
						<div> ALL NEWS </div>
					</a>
				</div>
			</section>
		);

	}
}

export default newsTemplate;
