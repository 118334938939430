import React, { Component } from 'react';

export default function newsPagesWithData(WrappedComponent, options = {}) {
    const HOC = class extends Component {
        constructor(props) {
            super(props);
            this.state = {
                newsItems: [],
                loading: true,
                error: null
            };
        }

        componentDidMount() {
            fetch('/news?id=' + this.props.match.params.news , {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => response.json())
            .then(data => {
                this.setState({ newsItems: data, loading: false });
            })
            .catch(error => {
                this.setState({ error, loading: false });
            });
        }

        render() {

            const { newsItems, loading, error } = this.state;
            if(loading){
            	return<p>Loading...</p>
            }
            if(error){
            	return<p>{error}</p>
            }
            return <WrappedComponent data={this.state} {...this.props} />;
        }
    };

    return HOC;
}
