import React, { Component } from 'react';
import image from '../../img/Cubs_News_Bullseye_Final.png';
import Moment from 'moment';
import ReactPlayer from 'react-player/file'
import VideoThumbnail from '../gallery/VideoThumbnail'

function Item(items){
	let temp = items.items.gallery.props.homePageQuery.galleryPages[0]?.galleryGroups || []

	let allItems=[];

	temp.forEach((group) => {
	  if (group.galleryItems && group.galleryItems.length > 0) {
	    group.galleryItems.forEach((item) => {
	      if (item.file.url.includes('videos')) {
	        allItems.push(item);
	      }
	    });
	  }
	});

	allItems.sort(function compare(a, b) {
	    let dateA = new Date(a.releaseDate);
	    let dateB = new Date(b.releaseDate);
	    return dateB - dateA;
	  });


	return (<div> {allItems.slice(0,3).map(function(link,index)
	{
		return <div key={index} id={link.name} className="col md-4 news flex-item">
          <div className="news-img-container">
            <div className='player-wrapper'>
              <div className='player-wrapper'>
              <ReactPlayer 
              	config={{ file: { attributes: { controlsList: 'nodownload' }}}} 
              	id={link.name}
              	onStart={() => window.dataLayer.push({'event': 'gallery_video_start', 'video_name': item.name })}
    						onEnded={() => window.dataLayer.push({'event': 'gallery_video_end', 'video_name': item.name })}
              	className='react-player' 
              	controls={true} 
              	light=<VideoThumbnail
				    videoUrl={link.file.url}
				    thumbnailHandler={(thumbnail) => (thumbnail)}
				    snapshotAtTime={link.snapshotTime}
			    /> 
			    muted={true} 
			    width="100%" 
			    height="100%" 
			    url={link.file.url} />
              </div>
            </div>
          </div>
          <h3><b>{link.name}</b></h3>
          <p>{Moment.utc(link.releaseDate).format('MM/DD/YYYY')}</p>
          <p>{link.description}</p>
		</div>
	}
	)}			
		</div>
	);


}


class Gallery extends Component{

	render(){
		return(
			<section className="section highlight-section ">
				<header className="section-header row">
					<div className="col">
						<h2 className="section-title section-title-left">Video Series</h2>
						<a href="/video-series" className="view-all">View all Series</a>
					</div>
				</header>
				<div className = "section-content row cubs-news"> 
					<Item items = {this.props}/>
				</div>
			</section>

		);

	}

}

export default Gallery;