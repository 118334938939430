import React, { Component } from 'react';

function IsEmptyLinks(links){
	if (links.length != null){
		return <QuickLinks links = {links.length}/>;
	}
	return <div/>;
}	

function IsEmptyDocs(docs){
	if (docs.length != null){
		return <Documents docs = {docs}/>;
	}
	return <div/>;
}

function QuickLinks(links){
	return  (<ul> {links.links.map(function(link,index){return <li key={index}> 
		<a href= {links.links[index].route}>{links.links[index].text}</a></li>;})}
	</ul>);
}

function Documents(docs){
	return (	<ul> {docs.docs.length.map(function(doc,index){return <li key={index}> 
		<a href= {docs.docs.length[index].fileLink.url}>{docs.docs.length[index].text}</a></li>;})}
	</ul>);
}

class quicklinks extends Component{

	render(){
		return(
			<div className="col sm-12 md-4 quick-links2">
				<h2 className= 'section-title'> {this.props.links.quickLinksHeader}</h2>
				<IsEmptyLinks length = {this.props.links.quickLinks}/>
				<h2 className='section-title'>{this.props.links.popularDocumentsHeader}</h2>
				<IsEmptyDocs length = {this.props.links.popularDocuments}/>
			</div>
		);

	}
}

export default quicklinks;