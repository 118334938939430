import React, { Component} from 'react';
import './dataTable/css/table-twbs.css';
import _ from 'underscore';

let DataTable = require('./dataTable').DataTable;
let arr = [];
  
class genericList extends Component{

	constructor(props) {
		super(props);
		if (this.props.list.location.pathname === '/associate-roster') {
			this.state = {temp: [], object: [],
				columns: [{
					prop: 'associate',
					title: 'Associate',
				}, {
					prop: 'title',
					title: 'Position'
				}, {
					prop: 'department',
					title: 'Team',
				}, {
					prop: 'phone',
					title: 'Phone',
				}, {
					prop: 'email',
					title: 'Email',
				}],
				location: this.props.list.location.pathname,
				dataLoaded: false
			};
		}
		else {
			this.state = {temp: [], object: [this.props.list.listPageQuery.genericPageListingOfFiles[1].filesAndDocuments],
				columns: [{
					prop: 'title',
					title: 'Name',
				}, {
					prop: 'department',
					title: 'Department',
				}, {
					prop: 'uploadDate',
					title: 'Date Modified',
				}],
				location: this.props.list.location.pathname,
				dataLoaded: false
			};
		}
		this.tempFiles = this.props.list.listPageQuery.genericPageListingOfFiles;
		this.componentDidMount = this.componentDidMount.bind(this);
		this.buildRowOptions = this.buildRowOptions.bind(this);
		this.Map = this.Map.bind(this);
		this.checkBox = this.checkBox.bind(this);
	}
	
	Map(depts){
		let location = depts.props.list.location.pathname;
		let i,index;
		for (i = 0; i < this.tempFiles.length; i++){
			if ('/' + this.tempFiles[i].route === location){
				index = i;
				break;
			}
		}
		let temp = depts.props.list.listPageQuery.genericPageListingOfFiles[i];

		let self = this;
		let key;

		return (
			<fieldset className='form-group'>
				{temp.department.map(function(link,index)
				{key = index + 'x';
				
					return(

						<label key={key} className="checkbox black" htmlFor={temp.department[index]}>
							<input value={temp.department[index]} type="checkbox" id={temp.department[index]} onClick={ ()=> self.checkBox(self.state.object, temp.department[index])}/>
							{temp.department[index]}
						</label>);
				}
				)}
			</fieldset>
		);

	}

	checkBox(obj, dept){
		let temp = this.state.temp;
		if (!arr.includes(dept)){
			arr.push(dept);
			obj = _.where(obj, {department: dept});
			this.setState({object: obj});
		}
		else{
			arr = _.filter(arr, function(value){
				return value !== dept;
			});
			if (arr.length > 0){
				for (let i = 0; i < arr.length; i++){
					temp = _.filter(temp, {department: arr[i]});
				}
				this.setState({object: temp});
			}	
			else{
				this.setState({object: this.state.temp});
			}
		}
		
	}

	buildRowOptions(row) {
		let temp = row;
		if (row.associate == null){
			return {
				onClick: function() {
					window.location = temp.fileUpload.url;
				}
			};
		}
	}

	//handle data loading in componentDidMount
	componentDidMount(){
		let location = this.state.location;
		let index, i;
		for (i = 0; i < this.tempFiles.length; i++){
			if ('/' + this.tempFiles[i].route === location){
				index = i;
				break;
			}
		}
		if (location === '/associate-roster'){
			fetch('/employee', {
				headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
			})
				.then(response => response.json())
				.then(associate => {	
					//console.log(associate);			
					associate.associates.sort(function(a, b){
						if(a.associate < b.associate) { return -1; }
						if(a.associate > b.associate) { return 1; }
						return 0;
					});
					this.setState({object: associate.associates});
					this.setState({temp: associate.associates});
					this.setState({dataLoaded: true});	
				});
				
		}
		else{
			let file = this.tempFiles[index].filesAndDocuments;	
			this.setState({object: file});
			this.setState({temp: file});
			this.setState({dataLoaded: true});	
		}

	}

	render(){
		const depts = this.props.list.listPageQuery;
		let dataLoaded = this.state.dataLoaded;
		let key;
		if (!dataLoaded){
			return <div> Loading... </div>;
		}
		if (this.props.list.location.pathname === '/associate-roster')
			key = 'associate';
		else
			key = 'title';
		return(
			<section className="section">
				<header className='section-header row'>
					<div className = 'col'>
						<h2 className = 'section-title'> {depts.genericPageListingOfFiles[0].filesAndDocumentsHeader} </h2>
					</div>
				</header>
				<div className= 'section-content row cubs-spotlight'>
				{ key != 'associate' && 
					<div className= 'col md-3 spotlight'>
						<form action="#" method="get" id="deptForm" noValidate="novalidate">
							{this.Map(this)}
						</form>
					</div>
				}
					<div className='col md-9 spotlight black'>
						<DataTable className="black"
							keys= {key}
							columns={this.state.columns}
							initialData={this.state.object}
							buildRowOptions = {this.buildRowOptions}
						/>
					</div>
				</div>
			</section>
		);

	}
}

export default genericList;