import React, { Component } from 'react';
import Moment from 'moment';
import ReactPlayer from 'react-player/file'
import VideoThumbnail from './VideoThumbnail'


function QueryGallery({ gallery, selectedMonth, selectedYear, selectedGroup, length }) {

    let items = gallery.items;

	  if (!selectedMonth && !selectedYear && !selectedGroup) {
	    // Handle the case when no filters are selected
	  }else{

	  	if (selectedMonth) {
	    	items = items.filter((item) => Moment(item.releaseDate).month() === parseInt(selectedMonth));
	  	}

	  	if (selectedYear) {
	    	items = items.filter((item) => Moment(item.releaseDate).year() === parseInt(selectedYear));
	  	}

	  	if (selectedGroup) {
	    	items = items.filter((item) => gallery.groupName === selectedGroup);
	  	}
	  }
		if(items.length === 0){
			return (
		        <div className="news-section">
		          <p>No items match the selected criteria.</p>
		        </div>
		      );
		} else{
			console.log(items)
			return (
		      <div className="news-section">
		        <div className="section-content row cubs-news">
		          {items.map((item, itemIndex) => (
		          	item.featuredVideo === true && item.name =='Our Vision, Mission and Values' ? null :(
			            <div key={itemIndex} className="col md-6 flex-item gallery-item">
			              	{item.file.url.includes('videos') ? 
			              		<div className="news-img-container">
				              		<div className='player-wrapper'>
						                <ReactPlayer 
						                  	config={{ file: { attributes: { controlsList: 'nodownload' }}}}
						                  	id={item.name} 
						                  	className='react-player' 
						                  	onStart={() => window.dataLayer.push({'event': 'gallery_video_start', 'video_name': item.name })}
		    								onEnded={() => window.dataLayer.push({'event': 'gallery_video_end', 'video_name': item.name })}
						                  	controls={true} 
						                  	light=<VideoThumbnail
											    videoUrl={item.file.url}
											    thumbnailHandler={(thumbnail) => (thumbnail)}
										    	snapshotAtTime={item.snapshotTime} 
										    /> 
										    muted={true} 
										    width="100%" 
										    height="100%"
										    url={item.file.url} />
									</div>
						            {item.showDetails && 
						            	<>
							            	<h3><b>{item.name}</b></h3>
								            <p>{Moment.utc(item.releaseDate).format('MM/DD/YYYY')}</p>
								            <p>{item.description}</p>  
						            	</>
							        }
				                </div>
			                :
			                	<div className="news-img-container">
			                		{item.openableFileLink ?
				                		<a href={item.file.url} target="_blank">
						              		<div className='player-wrapper'>
												<img src= {item.fileThumbnailOverride?.url ? item.fileThumbnailOverride.url : item.file.url} alt="news"/>
											</div>
							           	</a>
							        :
							        	<div className='player-wrapper'>
											<img src= {item.fileThumbnailOverride?.url ? item.fileThumbnailOverride.url : item.file.url} alt="news"/>
										</div>
							    	}
						            {item.showDetails && 
						            	<>
								            <h3><b>{item.name}</b></h3>
								            <p>{Moment.utc(item.releaseDate).format('MM/DD/YYYY')}</p>
								            <p>{item.description}</p>  
							            </>
							        }
				                </div>
			            	}
			            </div>
		          )))}
		        </div>
		      </div>
		    );
		}
}


export default QueryGallery;