import React, { Component } from 'react';

class Image extends Component{

	render(){
		const images = this.props.introBackgrounds;
		return(
			<img src={images.url} alt={images.title} className="featured-image"/>
		);

	}
}

export default Image;