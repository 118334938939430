import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import {gql} from '@apollo/client';
import { withRouter } from 'react-router-dom';

export default function eventPagesWithData(WrappedComponent, options = {}) {
	const HOC = class extends Component {
		constructor(props) {
			super(props);
			this.wrapped = graphql(gql `query eventsPageQuery {
				eventItems(q:"fields.route=${props.match.params.eventRoute}") {
				  recurringDates
		          eventTime {
		            startHourmilitaryTime
		            startMinute
		            endHourmilitaryTime
		            endMinute
		          }
		          eventDate
				  eventTitle
				  route
				  eventLocation
				  descriptionHeader
				  eventDescription
		          eventImage {
		            title
		            description
		            url
		          }
				} 
			  eventsPages {
			    backgroundHeader
			    backgroundDescription
			    eventHeader
			    backgroundImage {
			      title
			      description
			      url
			    }    
			  }
			  feedbackComponents {
			    feedbackHeader
			    feedbackDescription
			    feedbackCtAtext
			    feedbackImage {
			      title
			      description
			      url
			    }
			  }
			  headerFooterComponents {
			    footerHeader
			    footerBody
			    copyrightHolder
			    primaryNavigationLinks{
			      route
			      text
			    }
			    footerLinks{
			      route
			      text
			    }
			    secondaryFooterLinks {
			      route
			      text
			    }
			    footerLogo {
			      title
			      description
			      url
			    }
			  }
			  exploreComponents {
			      isNotDisplayed
			      exploreTitle
			      exploreTile {
			      backgroundImage {
			            title
			            description
			            url
			          }
			        route
			        title
			        ctaText
			    }
			  }
			  globalSites{feedback {
						 feedbackHeader
						 feedbackDescription
						 feedbackCtAtext
					   feedbackImage {
						 title
						 description
						 url
						}
					}
				  mainNavigation {
						 footerHeader
						 footerBody
						 copyrightHolder
						 primaryNavigationLinks{
							 route
							 text
						 }
						 footerLinks{
						   route
						   text
						 }
						 secondaryFooterLinks {
						   route
						   text
						 }
						 footerLogo {
						   title
						   description
						   url
						 }
				   }
				   cubsLogo{
		              title
		              url
		            }
			  }
}
	  `, { name: 'eventsPageQuery' })(WrappedComponent);
		}

		render() {
			const Wrapped = this.wrapped;
			return <Wrapped {...this.props}/>;

		}
	};

	return HOC;
}