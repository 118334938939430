import React, { Component } from 'react';
import everybodyIn from '../../img/everybodyin.jpg';
import ReactMarkdown from 'react-markdown';
import _ from 'underscore';
import Moment from 'moment';
import ReactPlayer from 'react-player/file';

function Map(news){
	Moment.locale('en');
	let temp = news.news.props.news.props.genericPageQuery.newsItems;
	if (temp == null){
		return <div className='section-content row cubs-news'/>;
	}

	temp = _.filter(temp, function(dept){
		if(dept.department){
			for (let i = 0; i < dept.department.length; i++){
				if (dept.department[i].route === news.news.props.news.props.match.params.department)
					return true;
			}
		}
		
	});

	temp.sort(function compare(a, b) {
		let dateA = new Date(a.date);
		let dateB = new Date(b.date);
		return dateB - dateA;
	});

	return(
		<ul><div className='section-content row cubs-news'>
			{temp.map(function(link,index)
			{
				if (temp[index].image != null){
					return <li key={index}> 		
						<div className="col md-6 news">
							<a href={'/cubs-news-departments/'+ (temp[index].route)}>
								<div className="news-img-container">
									<img src= {temp[index].image.url} alt="news"/>
								</div>
							</a>
							<h3>
								<a href={'/cubs-news-departments/'+ (temp[index].route)}>
									{temp[index].newsTitle}
								</a>
							</h3>
							<p>{Moment(temp[index].date).format('MM/DD/YYYY')}</p>
							<div> <ReactMarkdown children={temp[index].description.substring(0,80) + '...'}/> <a href = {'/cubs-news-departments/'+ (temp[index].route)}> Read More </a> </div>
						</div>
					</li>;}
				else {
					if (temp[index].videoLink == null){
						return <li key={index}> 
							<div className="col md-6 news">
								<a href={'/cubs-news-departments/'+ (temp[index].route)}>
									<div className="news-img-container">
										<img src= {everybodyIn} alt="news"/>
									</div>
								</a>
								<h3>
									<a href={'/cubs-news-departments/'+ (temp[index].route)}>
										{temp[index].newsTitle}
									</a>
								</h3>
								<p>{Moment(temp[index].date).format('MM/DD/YYYY')}</p>
								<p> {'Department: ' + temp[index].department.map(function(item,i){return temp[index].department[i].name})}</p>
								<div> <ReactMarkdown children={temp[index].description.substring(0,80) + '...'}/> <a href = {'/cubs-news-departments/'+ (temp[index].route)}> Read More </a> </div>
							</div>
						</li>;}
					return <li key={index}> 
						<div className="col md-6 news">
							<a href={'/cubs-news-departments/'+ (temp[index].route)}>
								<div className="news-img-container">
									{/*<iframe src= {`https://player.vimeo.com/video/`+temp[index].videoLink} title={temp[index].newsTitle} alt="news"/>*/}
									<div className='player-wrapper'><ReactPlayer config={{ file: { attributes: { controlsList: 'nodownload' }}}} className='react-player' controls={true} muted={true} width="100%" height="100%" url={temp[index].videoLink} /></div>
								</div>
							</a>
							<h3>
								<a href={'/cubs-news-departments/'+ (temp[index].route)}>
									{temp[index].newsTitle}
								</a>
							</h3>
							<p>{Moment(temp[index].date).format('MM/DD/YYYY')}</p>
							<p> {'Department: ' + temp[index].department.map(function(item,i){return temp[index].department[i].name})}</p>
							<div> <ReactMarkdown children={temp[index].description.substring(0,80) + '...'}/> <a href = {'/cubs-news-departments/'+ (temp[index].route)}> Read More </a> </div>
						</div>
					</li>;
				}
			})
			}
		</div>
		</ul>);

}

class newsAndInfo extends Component{

	render(){
		const temp = this;
		return(
			<div className="col sm-12 md-8">
				<header className='section-header'>
					<h2 className='section-title'>{temp.props.header}</h2>
				</header>
				<Map news = {temp}/>
			</div>
		);

	}
}

export default newsAndInfo;