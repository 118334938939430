import React, { Component } from 'react';

class Mission extends Component{

	render(){
		const mission = this.props.mission;
		return(
			<section className="mission-section">
				<div className="row mission-statement center">
					<a href="/video-series">
						<img src={mission.backgroundImage.url} alt ="mission background"/>
					</a>
				</div>
			</section>

		);
	}
}

export default Mission;