import React, { Component } from 'react';
import image from '../../img/Cubs_News_Bullseye_Final.png';
import Moment from 'moment';

function Map(news){
	let temp = news.news.props.data.newsItems.articles;

	temp.sort(function compare(a, b) {
		let dateA = new Date(a.publish_date);
		let dateB = new Date(b.publish_date);
		return dateB - dateA;
	});
	//console.log(temp);
	return (<div> {temp.slice(0,3).map(function(link,index)
	{
		if (temp[index].image != null){
			return <li key={index}> 		
				<div className="col md-4 news">
					<a href={'/cubs-news/'+ (link.id)}>
						<div className="news-img-container">
							{link.hero_image ? <img height="230px" width="200px" src={link.hero_image} alt="news" /> : <img src={image} alt="news" /> }
							{/*<img src= {temp[index].image.url} alt="news"/>*/}
						</div>
					</a>
					<p>{Moment.utc(link.publish_date).format('MM/DD/YYYY')}</p>
					<h3>
						<a href={'/cubs-news/'+ (link.id)}>
							{link.email_header}
						</a>
						{link.stories.subject}
					</h3>
					{/*<p> {'Department: ' + temp[index].department.map(function(item,i){return temp[index].department[i].name})}</p>*/}
					<div className="news-text">{link.email_header}</div>
				</div>
			</li>;}
		else {
			if (temp[index].videoLink == null){
				return <li key={index}> 
					<div className="col md-4 news ">
						<a href={'/cubs-news/'+ (link.id)}>
							<div className="news-img-container">
								{link.hero_image ? <img height="230px" width="200px" src={link.hero_image} alt="news" /> : <img src={image} alt="news" /> }
								{/*<img src= {image} alt="news"/>*/}
							</div>
						</a>
						<p>{Moment.utc(link.publish_date).format('MM/DD/YYYY')}</p>
						<h3>
							<a href={'/cubs-news/'+ (link.id)}>
								{link.email_header}
								
							</a>
						</h3>
						<div>
							<a> </a>
						</div>
						<div className="news-text">
							{link.stories.slice(0, 3).map((story,index) => {
									return(
										<ol>
											<li key={index} id={`link-${index}`}>
											<a href={'/cubs-news/'+ (link.id)+ `#link-${index}`}>
												{story.subject}
											</a>
											</li>
										</ol>
										);
							})}
						</div>
						{/*<p> {'Department: ' + temp[index].department.map(function(item,i){return temp[index].department[i].name})}</p>*/}
					</div>
				</li>;}
			return <li key={index}> 
				<div className="col md-4 news">
					<a href={'/cubs-news/'+ (link.id)}>
						<div className="news-img-container">
							<iframe src= {temp[index].videoLink} title={link.email_header} alt="news"/>
						</div>
					</a>
					<p>{Moment.utc(link.publish_date).format('MM/DD/YYYY')}</p>
					<h3>
						<a href={'/cubs-news/'+ (link.id)}>
							{link.email_header}
						</a>
					</h3>
					{/*<p> {'Department: ' + temp[index].department.map(function(item,i){return temp[index].department[i].name})}</p>*/}
				</div>
			</li>;
		}
	})
	}
</div>
	);
}


class News extends Component{
	render(){
		return(
			<section className="section highlight-section ">
				<header className="section-header row">
					<div className="col">
						<h2 className="section-title section-title-left">Cubs News</h2>
						<a href="/cubs-news" className="view-all">View all news</a>
					</div>
				</header>
				<div className = "section-content row cubs-news"> 
					<Map news = {this.props.news}/>
				</div>
			</section>

		);

	}

}

export default News;