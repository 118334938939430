import React, { Component } from 'react';

class executive extends Component{

	render(){
		const executive = this.props.executive;
		return(
			<section className="section main-department">
				<div className="section-content row">
					<div className="col sm-12">
						<ul className="flex-container executives-grid">
							{this.props.executive.executive.map(function(exec,index){
								return <div className = "col sm-6 md-4 executives-tile">
									<div className="executives-tile-img">
										<img src = {executive.executive[index].picture.url} alt = "executive"/>
									</div>
									<h3>{executive.executive[index].name} </h3>
									<h4> {executive.executive[index].title}</h4>
								</div>;
							})}
						</ul>
					</div>
				</div>
			</section>
		);

	}
}

export default executive;