import React, { Component } from 'react';

class ValueImage extends Component{

	render(){
		const image = this.props.image;
		return(
			<div >
				<img className="value-image" src={image.valuesImage.url} alt="value"/>
			</div>
		);
	}
}

export default ValueImage;