import React, { Component } from 'react';
import everybodyIn from '../../img/Cubs_News_Bullseye_Final.jpg';
import ReactMarkdown from 'react-markdown';
import _ from 'underscore';
import Moment from 'moment';
let dateFormat = require('dateformat');

function Image(img){
	if (img.img.eventImage == null)
		return(<img src= {everybodyIn} alt = "default"/>);
	
	return (<img src= {img.img.eventImage.url} alt = "events"/>);
}

function Map(dates){
	let temp = dates.dates.recurringDates;
	let events= dates.dates;


	let startHour = events.eventTime.startHourmilitaryTime;
	let endHour = events.eventTime.endHourmilitaryTime;
	let startMinute = events.eventTime.startMinute;
	let endMinute = events.eventTime.endMinute;

	if (startMinute < 10){
		startMinute = '0'.concat(startMinute.toString());
	}

	if (startHour > 12){
		startHour -= 12;
	}

	if (endMinute < 10){
		endMinute = '0'.concat(endMinute.toString());
	}

	if (endHour > 12){
		endHour -= 12;
		endMinute = endMinute.toString().concat(' PM');
	} else if(endHour === 12){
		endMinute = endMinute.toString().concat(' PM');
	} else{
		endMinute = endMinute.toString() + ' AM';
	}

	temp = _.sortBy(temp, function(d){
		return new Date(d);
	});

	console.log()

	return (
		<ul >
			<span className = "place"> Dates & Times: </span>
			<li className = "event-details-dateTime">{Moment(events.eventDate, 'YYYY-MM-DD').format('MMMM DD, YYYY') + ' | ' +startHour + ':' + startMinute+ ' - ' +endHour+ ':' + endMinute}</li>
			{	
				temp && temp.map(function(date,index){
					return <li key={index} className = "event-details-dateTime"> {Moment(temp[index], 'MM-DD-YYYY').format('MMMM DD, YYYY') + ' | ' +startHour + ':' + startMinute+ ' - ' +endHour+ ':' + endMinute} </li>;
				})
			}
		</ul>
	);
}

class eventsTemplate extends Component{

	render(){
		const events = this.props.events;
		
		return(
			<section className="section">
				<div className="section-content row cubs-event-detail">
					<header className="section-header row">
						<div className="col">
							<h2 className="section-title main-title">{events.eventTitle}</h2>
						</div>
					</header>
					<div className="section-content row cubs-event-detail">
						<div className = "col sm-12 detail">
							<div className= "event-details">
								<Map dates = {events}/>
								<li> <span className="place"> Place: </span> {events.eventLocation}</li>
							</div>
							<h3 className="cubs-event-detail-description"> {events.descriptionHeader}</h3>
							<ReactMarkdown className = "black" children= {events.eventDescription}/>
						</div>
						<div className="md-7 detail center">
							<Image img={events}/>
						</div>
						<a href='/events'>
							<div> ALL EVENTS </div>
						</a>
					</div>
					

				</div>
			</section>
		);

	}
}

export default eventsTemplate;