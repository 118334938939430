import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import classnames from 'classnames';
import {withRouter } from 'react-router-dom';

import ogImg from '../../assets/img/og.jpg';

import Image from '../../components/shared/backgroundImage';

import Footer from '../../components/shared/Footer';
import NavBar from '../../components/shared/NavBar';
import Description from '../../components/shared/imageDescription';
import Explore from '../../components/shared/explore.js';
import AllNews from '../../components/news/allNews.js';




class news extends Component { 
	constructor(props) {
		super(props);
		this.onTransitionEnd.bind(this);
	}
	state = {
		transitionEnded: false,
	};
	onTransitionEnd = () => {
		this.setState({ transitionEnded: true }); 
	};

	render() {
		const heroClasses = classnames('hero', {
			animate: this.state.transitionEnded
		});
		//if this.props.newsPageQuery is null wait, if not null check state of homePageQuery until done loading
		if (!this.props.newsPageQuery){
			return <div className="u-hidden"> Waiting</div>;
		}

		else if (this.props.newsPageQuery && this.props.newsPageQuery.loading) { 
			return <div className="u-hidden">Loading</div>; 
		}

		else if (this.props.newsPageQuery && this.props.newsPageQuery.error) {
			return <div className="u-hidden">Error </div>;
		}

		//done loading
		this.data = this.props.newsPageQuery;
		if (!this.data) {
			window.location.href = '/404';
			return null;
		} 

		return(
			<div key="home" className={heroClasses}>   
				<Helmet>
					<meta property="og:image" content={ogImg}/>
					<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"/>
				</Helmet>
				<header id="site-header" className="container" role="banner">
					<a id="site-title" className="h1" href="/"> <img src={this.data.globalSites[0].cubsLogo.url} alt = "site-header"/></a>
					<Image introBackgrounds={this.data.newsPages[0]}/>
					<NavBar NavBar={this.data.globalSites[0].mainNavigation.primaryNavigationLinks}/>
					<div className="header-content">
						<div className="row2 padding-bottom">
							<Description description= {this.data.newsPages[0]}/>
						</div>
					</div>
				</header>
				<main className="container">
					<AllNews news = {this.data}/>
					<Explore explore={this.data.exploreComponents[0]}/>
				</main>
				<Footer footer={this.data.globalSites[0].mainNavigation}/>
			</div>
		);
		
		
	}

}

export default withRouter(news);
