import React, { Component } from 'react';
import everybodyIn from '../../img/Cubs_News_Bullseye_Final.png';
import Moment from 'moment';
import ReactPlayer from 'react-player/file'
import allNewsWithData from './allNewsWithData'

function QueryNews(news){
	Moment.locale('en');
	let temp = news.news;

	temp.sort(function compare(a, b) {
		let dateA = new Date(a.publish_date);
		let dateB = new Date(b.publish_date);
		return dateB - dateA;
	});
	
	if(!news.selectedMonth && !news.selectedYear){
		temp = temp.slice(0,3);
	}

 	if (news.selectedMonth) {
        temp = temp.filter(item => Moment(item.publish_date).month() === parseInt(news.selectedMonth));
    }

    if (news.selectedYear) {
        temp = temp.filter(item => Moment(item.publish_date).year() === parseInt(news.selectedYear));
    }




	return  (<ul>	<div className= "news-section">
		<div className="section-content row cubs-news"> { temp.map(function(story,index)
		{
			if (story.image != null){
				return <li key={index}> 		
					<div className="col md-4 news flex-item">
						<a href={'/cubs-news/'+ (story.id)}>
							<div className="news-img-container">
								<img height="250px" width="200px" src= {story.image.url} alt="news"/>
							</div>
						</a>
						<h3>
							<a href={'/cubs-news/'+ (story.id)}>
								<b>{story.email_header}</b>
							</a>
						</h3>
						<p>{Moment.utc(story.publish_date).format('MM/DD/YYYY')}</p>
						{/*{story.department && <p> {'Department: ' + story.department.map(function(item,i){return story.department[i].name})}</p>}*/}
						<div> <div className="news-text">						
							{story.stories.slice(0, 3).map((link,index) => {
									return(
										<ol>
											<li key={index} id={`link-${index}`}>
												<h3>{link.subject}</h3>
											</li>
										</ol>
										);
							})}</div> <a href = {'/cubs-news/'+ (story.id)}> Read More </a> </div>
					</div>
				</li>;}
			else {
				if (story.videoLink == null){
				return <li key={index}> 
					<div className="col md-4 news flex-item">
						<a href={'/cubs-news/'+ (story.id)}>
							<div className="news-img-container">
								{story.hero_image ? <img height="250px" width="200px" src={story.hero_image} alt="news" /> : <img src={everybodyIn} alt="news" /> }
							</div>
						</a>
						<h3>
							<a href={'/cubs-news/'+ (story.id)}>
								<b>{story.email_header}</b>
							</a>
						</h3>
						<p>{Moment.utc(story.publish_date).format('MM/DD/YYYY')}</p>
						{/*{story.department && <p> {'Department: ' + story.department.map(function(item,i){return story.department[i].name})}</p>}*/}
						<div> <div className="news-text">
							{story.stories.slice(0, 3).map((link,index) => {
									return(
										<ol>
											<li key={index} id={`link-${index}`}>
											<a href={'/cubs-news/'+ (story.id)+ `#link-${index}`}>
												{link.subject}
											</a>
											</li>
										</ol>
										);
							})}
						</div> <a href = {'/cubs-news/'+ (story.id)}> Read More </a> </div>
					</div>
				</li>;}
				return <li key={index}> 
					<div className="col md-4 news flex-item">
						<a href={'/cubs-news/'+ (story.id)}>
							<div className="news-img-container">
								{/*<iframe src= {`https://player.vimeo.com/video/`+story.videoLink} title={story.newsTitle} alt="news"/>*/}
								<div className='player-wrapper'><ReactPlayer config={{ file: { attributes: { controlsList: 'nodownload' }}}} className='react-player' controls={true} muted={true} width="100%" height="100%" url={story.videoLink} /></div>
							</div>
						</a>
						<h3>
							<a href={'/cubs-news/'+ (story.id)}>
								<b>{story.email_header}</b>
							</a>
						</h3>
						<p>{Moment(story.publish_date).format('MM/DD/YYYY')}</p>
						{/*{story.department && <p> {'Department: ' + story.department.map(function(item,i){return story.department[i].name})}</p>}*/}
						<div> <div className="news-text">
						{story.stories.slice(0, 3).map((link,index) => {
									return(
										<ol>
											<li key={index} id={`link-${index}`}>
												<h3>{link.subject}</h3>
											</li>
										</ol>
										);
								})}
							</div> <a href = {'/cubs-news/'+ (story.id)}> Read More </a> </div>
					</div>
				</li>;
			}
		})
		}
		</div>
	</div></ul>);
				

}

class allNews extends Component {
    constructor(props) {
        super(props);
        this.state = {selectedMonth: '', 
        selectedYear: ''};
        this.handleMonthChange = this.handleMonthChange.bind(this);
        this.handleYearChange = this.handleYearChange.bind(this);
    }

    handleMonthChange(event) {
        this.setState({selectedMonth: event.target.value});
    }

    handleYearChange(event) {
        this.setState({selectedYear: event.target.value});
    }

    render() {
        const news = this.props.data.newsItems.articles;
        const months = Moment.months().map((month, index) => (
            <option key={index} value={index}>{month}</option>
        ));
        const years = Array.from({length: 2}, (_, i) => new Date().getFullYear() - i);
        const yearOptions = years.map((year, index) => (
            <option key={index} value={year}>{year}</option>
        ));

        return (
            <section className="section">
            	<div className="container-fluid">
                <header className="section-header ">
                	<div className="col">
                        <h3 className="section-title row news-layout"> Looking Back</h3>
                    </div>
                   {/* <div className="col">
                        <h2 className="section-title row news-layout"> {news.newsPages[0].newsHeader}</h2>
                    </div>*/}
                </header>
                <div className="row news-layout">
                	<div className="col md-2 ">
                	    <div className="select-month-year">
                            <div className="select-year">
                                <select value={this.state.selectedYear} onChange={this.handleYearChange}>
                                    <option value="">Select Year</option>
                                    {yearOptions}
                                </select>
                            </div>
                            <div className="select-month">
                                <select disabled={!this.state.selectedYear} value={this.state.selectedMonth} onChange={this.handleMonthChange}>
                                    <option value="">Select Month</option>
                                    {months}
                                </select>
                            </div>
                        </div>
            		</div>
            		<div className="col md-10 ">
                	<QueryNews news={news} selectedMonth={this.state.selectedMonth} selectedYear={this.state.selectedYear} />
            		</div>
            	</div>
            	</div>
            </section>
        );
    }
}

export default allNewsWithData(allNews);