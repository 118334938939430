import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import classnames from 'classnames';
import {withRouter } from 'react-router-dom';

import ogImg from '../../assets/img/og.jpg';

import Image from '../../components/shared/backgroundImage';

import Footer from '../../components/shared/Footer';
import NavBar from '../../components/shared/NavBar';
import Description from '../../components/shared/imageDescription';
import Box from '../../components/shared/box';
import Explore from '../../components/shared/explore.js';



class generic extends Component { 
	constructor(props) {
		super(props);
		this.onTransitionEnd.bind(this);
		this.state = {
			index: null,
			data: null,
			loaded: false,
			transitionEnded: false,
		};
		
	}
	onTransitionEnd = () => {
		this.setState({ transitionEnded: true }); 
	};
	componentDidUpdate(){
		let location = this.props.location.pathname;
		location = location.toLowerCase();
		let path;
		//console.log('------');
		if (this.state.index == null){
			if (!this.data){
				//console.log('inside if statement');
			}
			//console.log(this.data);
			if (this.data){
				path = this.data.genericPageBoxes;
				
				let i;
				//console.log("data has been filled");

				for (i = 0; i < path.length; i++){
					//console.log(path[i].route);
					if (location === '/' + path[i].route){
						this.setState({index: i});
						break;
					}
				}
			}
		}
	}

	render() {
		const heroClasses = classnames('hero', {
			animate: this.state.transitionEnded
		});
		//if this.props.genericPageQuery is null wait, if not null check state of homePageQuery until done loading
		if (!this.props.genericPageQuery){
			return <div className="u-hidden"> Waiting</div>;
		}

		else if (this.props.genericPageQuery && this.props.genericPageQuery.loading) { 
			return <div className="u-hidden">Loading</div>; 
		}

		else if (this.props.genericPageQuery && this.props.genericPageQuery.error) {
			return <div className="u-hidden">Error </div>;
		}

		//done loading
		else {
			this.data = this.props.genericPageQuery;
			if (!this.data) {
				window.location.href = '/404';
				return null;
			} 
			else{
				//console.log(this);
				if (this.state.index != null){
					//console.log("reached render");
					return(
						<div key="home" className={heroClasses}>   
							<Helmet>
								<meta property="og:image" content={ogImg}/>
								<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"/>
							</Helmet>
							<header id="site-header" className="container" role="banner">
								<a id="site-title" className="h1" href="/"> <img src={this.data.globalSites[0].cubsLogo.url} alt = "site-header"/></a>
								<Image introBackgrounds={this.data.genericPageBoxes[this.state.index]}/>
								<NavBar NavBar={this.data.globalSites[0].mainNavigation.primaryNavigationLinks}/>
								<div className="header-content">
									<div className="row2 padding-bottom">
										<Description description= {this.data.genericPageBoxes[this.state.index]}/>
									</div>
								</div>
							</header>
							<main className="container">
								<Box box= {this.data.genericPageBoxes[this.state.index]}/>
								<Explore explore={this.data.exploreComponents[0]}/>
							</main>
							<Footer footer={this.data.globalSites[0].mainNavigation}/>
						</div>
					);
				}
				else{
					return <div className="u-hidden">Loading</div>; 
				}
			}
		
		}
	}
}

export default withRouter(generic);