import React, { Component } from 'react';

class Tweet extends Component{

	render(){
		const tweet = this.props.tweet;
		if(tweet.route) {
			return(
				<div>
					<blockquote>&#34;{tweet.tweet}&#34;</blockquote>
					<span className="tweet-text">
						<a className="header-content-link" href={tweet.route}>&#64;{tweet.twitterHandle}</a>
					</span>

				</div>
			);
		} else {
			return(
				<div>
					<blockquote>{tweet.tweet}</blockquote>
				</div>
			);
		}

	}

}
export default Tweet;