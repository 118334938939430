import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import {gql} from '@apollo/client';
import { withRouter } from 'react-router-dom';

export default function listWithData(WrappedComponent, options = {}) {
	const HOC = class extends Component {
		constructor(props) {
			super(props);
			this.wrapped = graphql(gql `query listPageQuery {
			  genericPageListingOfFiles {
			  	filesAndDocuments {
		            department
		            team
		            title
		            uploadDate
		            fileUpload {
		              url
		            }
		          }
			  	route
			    backgroundHeader
			    backgroundDescription
			    filesAndDocumentsHeader
			    department
			    backgroundImage {
			      title
			      description
			      url
			    }
			  }
			  feedbackComponents {
			    feedbackHeader
			    feedbackDescription
			    feedbackCtAtext
			    feedbackImage {
			      title
			      description
			      url
			    }
			  }
			  headerFooterComponents {
			    footerHeader
			    footerBody
			    copyrightHolder
			    primaryNavigationLinks{
			      route
			      text
			    }
			    footerLinks{
			      route
			      text
			    }
			    secondaryFooterLinks {
			      route
			      text
			    }
			    footerLogo {
			      title
			      description
			      url
			    }
			  }
			  exploreComponents {
			      isNotDisplayed
			      exploreTitle
			      exploreTile {
			      backgroundImage {
			            title
			            description
			            url
			          }
			        route
			        title
			        ctaText
			    }
			  }
			  globalSites{feedback {
						 feedbackHeader
						 feedbackDescription
						 feedbackCtAtext
					   feedbackImage {
						 title
						 description
						 url
						}
					}
				  mainNavigation {
						 footerHeader
						 footerBody
						 copyrightHolder
						 primaryNavigationLinks{
							 route
							 text
						 }
						 footerLinks{
						   route
						   text
						 }
						 secondaryFooterLinks {
						   route
						   text
						 }
						 footerLogo {
						   title
						   description
						   url
						 }
				   }
				   cubsLogo{
		              title
		              url
		            }
			  }
}
	  `, { name: 'listPageQuery' })(WrappedComponent);
		}

		render() {
			const Wrapped = this.wrapped;
			return <Wrapped {...this.props}/>;

		}
	};

	return withRouter(HOC);
}